import React from 'react';
import ApiContext, {
  ApiContextValue,
  Customer,
  CustomerColor,
  CustomerToImport,
  ImportCustomerJobErrorResult,
  ImportCustomerJobSuccessResult,
  ImportCustomerResponse,
} from "./ApiContext";
import firebase, {User} from "firebase";
import {CoordinatesByAddressResponse, LatLng, Res} from "./CoordinatesByAddressResponse";
import {identity, pickBy} from "lodash-es";
import {RootsForCurrentUserResponse} from "./RootsForCurrentUser";
import {WithTranslation, withTranslation} from "react-i18next";
import transformCustomField from "./transformCustomField";

interface OwnProps {
}

type Props = OwnProps & WithTranslation;

/*
 * Rappresenta il limite per le chiamate getCoordinatesByAddress
 * se è -1 allora non ci sono limiti
 */
type GeoCodingThresholds = Map<string, number>

type LatLngOrErr = LatLng | { err: number };

const API_KEY = process.env.REACT_APP_API_KEY;
const coordinatesFromAddressCache: { [address: string]: LatLng } = {};

class ApiProvider extends React.Component<Props, ApiContextValue> {
  private geoCodingThresholds: GeoCodingThresholds = new Map();

  constructor(props: Props) {
    super(props);
    this.state = {
      startImportCustomersJob: this.startImportCustomersJob,
      importProgressPercent: 100
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(this.handleUser);
  }

  componentWillUnmount(): void {
    // This should be never called
  }

  render() {
    return (
      <ApiContext.Provider value={this.state} {...this.props}/>
    );
  }

  startImportCustomersJob: ApiContextValue['startImportCustomersJob'] = async (
    root, mappedSheet, color, keyMap
  ) => {
    const rootObject = this.state.roots?.find(x => x.root === root)!;

    try {
      transformCustomField(rootObject, keyMap, mappedSheet);
    } catch (e) {
      console.error(e);
      return {
        isDataImported: false,
        error: e
      }
    }

    // On some browsers we cannot use a custom message
    const onBeforeUnloadMessage = this.props.t('closeWindowDuringImportAlert');
    window.onbeforeunload = function () {
      return onBeforeUnloadMessage;
    };
    this.setState({importProgressPercent: 0});

    let toReturn: ImportCustomerJobErrorResult = {isDataImported: false};

    const errorsRows: ImportCustomerJobSuccessResult['errorsRows'] = [];
    const rowsToImport: Customer[] = [];

    try {
      const withNoLatLng: CustomerToImport[] = [];
      for (const row of mappedSheet) {
        // Copy object removing keys with null/undefined/empty string
        const customer: any = pickBy(row, identity);
        customer.lat = checkCoordinateValue(customer.lat);
        customer.lng = checkCoordinateValue(customer.lng);

        if (!row.name || !row.address) {
          errorsRows.push({err: -3, customer: row});
          continue
        }

        if (isNaN(customer.lat) || isNaN(customer.lng) || !(customer.lng > -180 && customer.lng < 180 && customer.lat > -85 && customer.lat < 85)) {
          withNoLatLng.push(customer)
        } else {
          rowsToImport.push(customer);
        }
      }

      const {results, outOfGeoCodingSlot} = await this.callGetCoordinatesByAddressForEachAddress(root, withNoLatLng.map(x => x.address));
      for (let i = 0; i < results.length; i++) {
        const customer = withNoLatLng[i];
        const result = results[i];
        if ('err' in result) {
          errorsRows.push({err: -4, customer})
        } else {
          customer.lat = result.lat;
          customer.lng = result.lng;
          rowsToImport.push(customer as Customer)
        }
      }

      const response = await this.callImportCustomers(root, rowsToImport, color);

      return {
        isDataImported: true,
        outOfGeoCodingSlot,
        importedRows: rowsToImport,
        errorsRows: errorsRows,
        serverResponse: response,
        root: rootObject
      }
    } catch (e) {
      toReturn.error = e;
    } finally {
      this.setState({importProgressPercent: 100});
      window.onbeforeunload = null;
    }

    return toReturn;

  };

  private handleUser = (user: User | null) => {
    if (user) {
      this.getRootsForCurrentUser().catch(console.error);
    } else {
      this.setState({roots: undefined})
    }
  };

  private async getRootsForCurrentUser() {
    const httpsCallable = firebase.functions().httpsCallable('getRootsForCurrentUser');
    const response = await httpsCallable({key: API_KEY});

    const data = response.data as RootsForCurrentUserResponse;

    if (data.code === 'OK') {
      const roots = data.roots;
      this.setState({roots});

      this.geoCodingThresholds.clear();
      for (const root of roots) {
        this.geoCodingThresholds.set(root.root, root.geocodingThreshold);
      }
    }
  }

  private callImportCustomers = async (root: string, customers: Customer[], color: CustomerColor) => {
    const httpsCallable = firebase.functions().httpsCallable('importCustomers');
    const response = await httpsCallable({
      key: API_KEY,
      root: root,
      customers,
      color
    });

    const data: ImportCustomerResponse = response.data;

    return data;
  };

  private callGetCoordinatesByAddressForEachAddress = async (root: string, addresses: string[]) => {
    const geoCodingThreshold = this.geoCodingThresholds.get(root);
    if (geoCodingThreshold == null) {
      throw Error('No geoCodingThreshold for current root')
    }

    const results: (LatLngOrErr)[] = [];
    let outOfGeoCodingSlot = false;

    for (let address of addresses) {
      /*
       * Questa è una richiesta del cliente: se le chiamate da fare sono troppe
       * bisogna chiudere a prescindere e notificare l'utente di contattarci
       */
      const remaining = addresses.length - results.length;
      if (geoCodingThreshold < remaining) {
        outOfGeoCodingSlot = true;
        break;
      }

      try {
        const result = await this.callGetCoordinatesByAddress(root, address);
        results.push(result);
      } catch (e) {
        results.push({err: -2});
      }
      this.setState({importProgressPercent: results.length / (addresses.length + 1) * 100});
    }

    return {
      results,
      outOfGeoCodingSlot,
    };
  };

  private callGetCoordinatesByAddress = async (root: string, address: string): Promise<LatLngOrErr> => {
    if (coordinatesFromAddressCache[address]) {
      return coordinatesFromAddressCache[address]
    }

    const httpsCallable = firebase.functions().httpsCallable('getCoordinatesByAddress');
    const response = await httpsCallable({
      key: API_KEY,
      address,
      root
    });

    const data: CoordinatesByAddressResponse = response.data;
    if (data.code === 'KO') {
      if (data.err === 9000) {
        this.geoCodingThresholds.set(root, 0);
      }
      return {err: data.err}
    }
    this.geoCodingThresholds.set(root, data.geocodingThreshold);

    const res: Res = JSON.parse(data.res);
    const location = res.results?.[0]?.geometry.location;
    if (location) {
      coordinatesFromAddressCache[address] = location;
    }
    return location || {err: -1};
  }
}

function checkCoordinateValue(val:any):Number {
  
  //it's ok for invalid string as "124.813.639" but empty string will return 0 
  const numberVal = Number(val)

  //it's ok for empty string but invalid string like "124.813.639" will return "124.813"
  const parsedVal = parseFloat(val)
  
  if (parsedVal === numberVal) { //check if both function return the same number
    return numberVal
  }

  return Number.NaN
}

export default withTranslation()(ApiProvider);
